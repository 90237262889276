var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(2),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(3),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/物物通切图01-j975mxvg1exh.png","alt":""}})])])]),_c('div',[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(4),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(5),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/物物通切图02-hvihgmxsijpo.png","alt":""}})])])]),_c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(6),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(7),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/物物通切图03-anf748azdsd9.png","alt":""}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topImg"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/物物通-q81ksh02yh59.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"wrapper_1200 dom1"},[_c('p',{staticClass:"title"},[_vm._v("物物通SaaS")]),_c('p',{staticClass:"titleText"},[_c('span',[_vm._v("该平台围绕海外百货店业务场景，其可一键为百货店老板生成自己的专属进货小程序，旨在为海外百货店老板打造专属优质进货平台；我们向百货店老板筛选商品价格有优势、商品品类齐全、商品数量丰富、商品质量有保障、采购服务体系完善的进货仓库。平台也会自动记录百货店老板进货的数据并形成展示页面方便百货店老板一键补货，让百货店老板采货流程更加便捷、放心。")]),_c('span',{staticClass:"icon1"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/Vector-821c0ssjruuc.png","alt":""}})]),_c('span',{staticClass:"icon2"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/Vectorxx1x-4dstvlpteupa.png","alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能一")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能二")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能三")]),_c('span')])
}]

export { render, staticRenderFns }